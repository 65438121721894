export const colors = {
  colorContrast: "#ffffff",

  colorPrimary: "#f5f5f5",
  colorPrimaryLight: "#e3e3e3",
  colorPrimaryDark: "#858585",

  colorDanger: "#fc5f5f",
  colorDangerLight: "#ffdfdf",
  colorDangerDark: "#8c4242",

  colorSuccess: "#72c857",
  colorSuccessLight: "#94d381",
  colorSuccessDark: "#5b9c47",

  colorDark: "#212121",
  colorDarklight: "#6d6d6d",
  colorDarkDark: "#000000",

  // New color primary red
  colorPrimaryRed: "#fc5f5f"
}
