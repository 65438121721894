import styled from "@emotion/styled"
import { colors } from "../../scss/theme/theme"

export const TitleText = styled.p`
  font-size: 1.4em;
  margin: 0.7em 0;
  font-weight: bold;
  font-family: "Roboto-Bold";
`
export const TitleTextRight = styled.h2`
  margin: 1em 0;
  font-weight: bold;
  text-align: center;
  font-family: "Roboto-Bold";
  font-size: 1rem;
`
export const SectionTitle = styled.h1`
  font-size: 1.4rem;
  margin-bottom: 0.3em;
  @media screen and (max-width: 992px) {
    font-size: 1rem;
    margin-bottom: 1em;
  }
`
export const SectionTitle2 = styled.h2`
  font-size: 1.3rem;
  margin-bottom: 0.3em;
  @media screen and (max-width: 992px) {
    font-size: 0.8rem;
  }
`
export const SearchBar = styled.div`
  margin: 1em 0;
  display: flex;
  align-items: center;
  input {
    width: 250px;
    height: 40px;
    padding: 0 1em;
    border: 1px solid ${colors.colorPrimaryLight};
    @media screen and (max-width: 992px) {
      width: 100%;
    }
  }
  .btn-search-icon {
    width: 40px;
    height: 40px;
    border: none;
    display: flex;
    cursor: pointer;
    font-size: 1.2em;
    align-items: center;
    justify-content: center;
    color: ${colors.colorContrast};
    background: ${colors.colorDanger};
  }
  .btn-search-text {
    height: 40px;
    border: none;
    display: flex;
    padding: 0 1em;
    font-size: 0.8rem;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: ${colors.colorContrast};
    background: ${colors.colorDanger};
    @media screen and (max-width: 992px) {
      width: 80px;
      padding: 0px 0.5em;
    }
  }
`

export const ContactBox = styled.div`
  margin: 3em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px dashed ${colors.colorPrimaryLight};
  border-bottom: 1px dashed ${colors.colorPrimaryLight};
  p {
    margin: 0.5em 0;
    span {
      font-size: 0.8rem;
    }
    a {
      font-family: "Lato", sans-serif;
      display: block;
      font-size: 1.5em;
      font-weight: 900;
      color: #fc5f5f;
    }
  }
  .btn-contact {
    margin: 0.5em 0;
    font-size: 1.2em;
    padding: 0.5em 2em;
    color: ${colors.colorContrast};
    background: #fc5f5f;
    @media screen and (max-width: 420px) {
      font-size: 1em;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    p {
      text-align: center;
    }
  }
`
export const Input = styled.div`
  display: flex;
  margin: 1em 0;
  padding: 0.5em;
  flex-wrap: wrap;
  position: relative;
  background: ${colors.colorPrimary};
  label {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    span {
      margin: 0 1em;
      padding: 0 0.5em;
      display: block;
      color: ${colors.colorContrast};
      background: ${colors.colorDanger};
    }
  }
  input[type="text"] {
    width: 50%;
    height: 40px;
    padding: 0 1em;
    border: 1px solid ${colors.colorPrimaryDark};
  }
  input:focus {
    border: 1px solid #fc5f5f !important;
    outline: none;
  }
  textarea:focus {
    border: 1px solid #fc5f5f !important;
    outline: none;
  }
  .input-checkbox {
    width: 50%;
    display: flex;
    label {
      margin: 1em;
      cursor: pointer;
      margin-left: 3em;
      position: relative;
      &::before {
        content: "";
        left: -25px;
        width: 13px;
        height: 13px;
        position: absolute;
        background: ${colors.colorContrast};
        border: 1px solid ${colors.colorDark};
      }
      &::after {
        opacity: 1;
        content: "";
        left: -21px;
        width: 6px;
        height: 12px;
        position: absolute;
        transition: 0.1s all ease;
        transform: rotate(80deg) scale(0);
        border-right: 3px solid ${colors.colorDanger};
        border-bottom: 3px solid ${colors.colorDanger};
      }
    }
    &__check-box {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input[type="radio"] {
      display: none;
    }
    input[type="radio"]:checked + label::after {
      opacity: 1;
      transform: rotate(30deg) scale(1);
    }
  }
  select {
    width: 50%;
    height: 40px;
    padding: 0 0.6em;
  }
  textarea {
    font-weight: 400;
    width: 50%;
    resize: none;
    height: 100px;
    padding: 0.5em 1em;
  }

  @media screen and (max-width: 769px) {
    width: 100%;
    display: block;
    margin: 0px;
    padding: 0px 0px 10px;
    label {
      width: 100%;
      justify-content: flex-start;
      padding: 20px 0px 10px;
    }
    input[type="text"] {
      width: 100%;
    }
    textarea {
      width: 100%;
    }
    select {
      color: #000000;
      width: 100%;
      text-indent: 10px;
    }
    .input-checkbox {
      width: 100%;
      label {
        margin-left: 2em;
        margin-top: 0px;
        padding-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }
  }
`

export const InputEn = styled.div`
  display: flex;
  margin: 1em 0;
  padding: 0.2em;
  flex-wrap: wrap;
  position: relative;
  label {
    display: flex;
    font-weight: 300;
    width: 40%;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    p {
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
    span {
      display: block;
      font-size: 18px;
      color: ${colors.colorDanger};
      padding: 0 0.2em;
    }
  }
  input[type="text"] {
    width: 60%;
    height: 50px;
    padding: 0 1em;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
  }
  input:focus {
    border: 1px solid #fc5f5f !important;
    outline: none;
  }
  textarea:focus {
    border: 1px solid #fc5f5f !important;
    outline: none;
  }
  select {
    width: 60%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    padding: 0 1em;
  }
  textarea {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    width: 60%;
    resize: none;
    height: 250px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    padding: 0.8em 1em;
  }
  input[type="file"] {
    display: none;
  }

  .file-upload-button {
    display: inline-block;
    background-color: #fff4f4;
    border: 1px solid #fff4f4;
    font-size: 20px;
    font-weight: 400;
    border-radius: 50px;
    transition: all 0.3s ease-out;
    cursor: pointer;
    width: auto;
    padding: 15px 75px;
    p {
      text-align: center;
    }
  }
  .file-upload-button:hover {
    background-color: rgb(255 244 244 / 70%);
    color: gray;
  }
  .file-name {
    display: flex;
    font-size: 14px;
    align-items: center;
    margin-left: 20px;
  }
  .twoCol {
    width: 29% !important;
  }
  .twoColf {
    margin-right: 2%;
  }

  @media screen and (max-width: 769px) {
    width: 100%;
    display: block;
    margin: 0px;
    padding: 0px 0px 10px;
    label {
      width: 100%;
      justify-content: flex-start;
      padding: 20px 0px 10px;
      p {
        font-size: 15px;
      }
    }
    input[type="text"] {
      width: 100%;
    }
    textarea {
      width: 100%;
      height: 150px;
    }
    select {
      color: #000000;
      width: 100%;
      text-indent: 10px;
    }
    .file-upload-button {
      width: 100%;
      padding: 10px;
    }
    .file-name {
      font-size: 12px;
      justify-content: center;
      margin-top: 15px;
      margin-left: 0;
    }

    .twoCol {
      width: 100% !important;
    }
    .twoColf {
      margin-bottom: 15px;
      margin-right: 0;
    }
  }
`

export const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "95%",
  },
}
